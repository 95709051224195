$(document).on('turbolinks:load', function () {

    $('.video-slider').slick({
        dots: false,
        infinite: true,
        speed: 500,
        arrows: true,
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow:'<span class="prev_arrow slider_arrow"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M9.57 5.93L3.5 12L9.57 18.07" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>\n' +
            '<path d="M20.5 12H3.66998" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>\n' +
            '</svg></span>',
        nextArrow:'<span class="next_arrow slider_arrow"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M14.43 5.93L20.5 12L14.43 18.07" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>\n' +
            '<path d="M3.50002 12H20.33" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>\n' +
            '</svg></span>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                    arrows: true,
                    centerMode: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: true,
                    centerMode: true,
                    infinite: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    centerMode: false,
                    infinite: true,
                }
            }
        ]
    });

});


// for slider preview in mobile

$(document).ready(function() {
    $('.video-play').click(function() {
        $('.video-play-mobile').attr('src', $(this).find('video').attr('src'));
    });
});